import React from 'react';
import { Plus } from 'lucide-react';

interface TodoFormProps {
  newTodo: string;
  setNewTodo: (value: string) => void;
  selectedCategory: string;
  setSelectedCategory: (value: string) => void;
  categories: string[];
  onSubmit: (e: React.FormEvent) => void;
}

export const TodoForm: React.FC<TodoFormProps> = ({
  newTodo,
  setNewTodo,
  selectedCategory,
  setSelectedCategory,
  categories,
  onSubmit,
}) => {
  return (
    <div className="mb-8 bg-white rounded-xl shadow-md p-6">
      <form onSubmit={onSubmit} className="flex gap-4 mb-4">
        <input
          type="text"
          value={newTodo}
          onChange={(e) => setNewTodo(e.target.value)}
          placeholder="Neue Aufgabe hinzufügen..."
          className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
        />
        <button
          type="submit"
          className="w-12 h-12 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center justify-center"
        >
          <Plus size={24} />
        </button>
      </form>

      <div className="flex flex-wrap gap-2 mt-4">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`px-4 py-2 rounded-lg transition-all transform hover:scale-105 ${
              selectedCategory === category
                ? 'bg-purple-500 text-white shadow-lg'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {category}
          </button>
        ))}
      </div>
    </div>
  );
};