import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { DragDropContext } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { TodoForm } from './TodoForm';
import { TodoCategory } from './TodoCategory';
import { ProgressBar } from './ProgressBar';
import { MotivationalModal } from './MotivationalModal';
import { Todo } from './types';
import { Link, useNavigate } from 'react-router-dom';
import { Settings as SettingsIcon, LogOut } from 'lucide-react';

const motivationalMessages = [
  "You're the hottest.",
  "You are the absolute hottest!",
  "You are simply the hottest there is!",
  "You're the ultimate hottest I've ever met!",
  "You are the unattainable super-horniest!",
  "Nobody can even come close to being as horny as you!",
  "You are the sickest, mega-horniest of them all!",
  "You are the hottest thing the universe has ever produced!",
  "You are the undisputed, unrivalled master of horniness!",
  "You are the all-surpassing, legendary horniest of all time!"
];

export default function TodoList() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [todos, setTodos] = useState<Todo[]>([]);
  const [newTodo, setNewTodo] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [editingTodo, setEditingTodo] = useState<{ id: string; text: string } | null>(null);
  const [completedCount, setCompletedCount] = useState(0);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!user) return;

    const userRef = doc(db, 'users', user.uid);
    onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        setCategories(userData.categories || []);
        setSelectedCategory(userData.categories[0] || '');
        setCompletedCount(userData.completedCount || 0);
      }
    });

    const q = query(collection(db, 'todos'), where('userId', '==', user.uid));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const todoList: Todo[] = [];
      snapshot.forEach((doc) => {
        todoList.push({ id: doc.id, ...doc.data() } as Todo);
      });
      setTodos(todoList);
    });

    return () => unsubscribe();
  }, [user]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
      toast.success('Erfolgreich abgemeldet');
    } catch (error) {
      toast.error('Fehler beim Abmelden');
    }
  };

  const showMotivationalMessage = (count: number) => {
    if (count % 10 === 0 && count > 0) {
      const messageIndex = (count / 10 - 1) % motivationalMessages.length;
      setModalMessage(motivationalMessages[messageIndex]);
      setIsModalOpen(true);
    }
  };

  const addTodo = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newTodo.trim() || !selectedCategory) return;

    try {
      await addDoc(collection(db, 'todos'), {
        text: newTodo,
        category: selectedCategory,
        completed: false,
        inProgress: false,
        userId: user?.uid,
        createdAt: new Date().toISOString(),
      });
      setNewTodo('');
      toast.success('Aufgabe hinzugefügt!');
    } catch (error) {
      toast.error('Fehler beim Hinzufügen der Aufgabe');
    }
  };

  const toggleCompleted = async (todo: Todo) => {
    try {
      if (!todo.completed) {
        await updateDoc(doc(db, 'todos', todo.id), {
          completed: true,
          inProgress: false,
        });
        
        const newCompletedCount = completedCount + 1;
        const userRef = doc(db, 'users', user!.uid);
        await updateDoc(userRef, {
          completedCount: newCompletedCount
        });
        
        showMotivationalMessage(newCompletedCount);
        
        setTimeout(async () => {
          await deleteDoc(doc(db, 'todos', todo.id));
        }, 1000);
        toast.success('Aufgabe erledigt!');
      }
    } catch (error) {
      toast.error('Fehler beim Aktualisieren der Aufgabe');
    }
  };

  const resetProgress = async () => {
    try {
      const userRef = doc(db, 'users', user!.uid);
      await updateDoc(userRef, {
        completedCount: 0
      });
      toast.success('Fortschritt zurückgesetzt!');
    } catch (error) {
      toast.error('Fehler beim Zurücksetzen');
    }
  };

  const toggleInProgress = async (todo: Todo) => {
    try {
      await updateDoc(doc(db, 'todos', todo.id), {
        inProgress: !todo.inProgress,
        completed: false,
      });
      toast.success(todo.inProgress ? 'Aufgabe pausiert' : 'Aufgabe in Bearbeitung');
    } catch (error) {
      toast.error('Fehler beim Aktualisieren der Aufgabe');
    }
  };

  const updateTodoText = async (id: string, newText: string) => {
    try {
      await updateDoc(doc(db, 'todos', id), {
        text: newText,
      });
      setEditingTodo(null);
      toast.success('Aufgabe aktualisiert!');
    } catch (error) {
      toast.error('Fehler beim Aktualisieren der Aufgabe');
    }
  };

  const deleteTodo = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'todos', id));
      toast.success('Aufgabe gelöscht!');
    } catch (error) {
      toast.error('Fehler beim Löschen der Aufgabe');
    }
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) return;

    const sourceCategory = result.source.droppableId;
    const destinationCategory = result.destination.droppableId;
    const todoId = result.draggableId;

    if (sourceCategory !== destinationCategory) {
      try {
        await updateDoc(doc(db, 'todos', todoId), {
          category: destinationCategory,
        });
        toast.success('Aufgabe verschoben!');
      } catch (error) {
        toast.error('Fehler beim Verschieben der Aufgabe');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-blue-50 p-4">
      <div className="max-w-[90rem] mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Meine Aufgaben</h1>
          <div className="flex items-center gap-4">
            <Link
              to="/settings"
              className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all text-gray-600 hover:text-gray-800"
            >
              <SettingsIcon size={20} />
              <span>Kategorien verwalten</span>
            </Link>
            <button
              onClick={handleLogout}
              className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-lg shadow-sm hover:bg-red-600 hover:shadow-md transition-all"
            >
              <LogOut size={20} />
              <span>Abmelden</span>
            </button>
          </div>
        </div>
        
        <ProgressBar
          completed={completedCount}
          total={todos.length + completedCount}
          onReset={resetProgress}
        />

        <TodoForm
          newTodo={newTodo}
          setNewTodo={setNewTodo}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categories={categories}
          onSubmit={addTodo}
        />

        <DragDropContext onDragEnd={onDragEnd}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {categories.map((category) => (
              <TodoCategory
                key={category}
                category={category}
                todos={todos}
                onEdit={setEditingTodo}
                onComplete={toggleCompleted}
                onProgress={toggleInProgress}
                onDelete={deleteTodo}
                editingTodo={editingTodo}
                updateTodoText={updateTodoText}
              />
            ))}
          </div>
        </DragDropContext>

        <MotivationalModal
          message={modalMessage}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
}