import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../lib/firebase';
import { useNavigate } from 'react-router-dom';
import { PlusCircle, X } from 'lucide-react';
import toast from 'react-hot-toast';

export default function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tabs, setTabs] = useState<string[]>([]);
  const [newTab, setNewTab] = useState('');
  const navigate = useNavigate();

  const handleAddTab = () => {
    if (tabs.length >= 6) {
      toast.error('Maximal 6 Kategorien erlaubt');
      return;
    }
    if (newTab.trim() && !tabs.includes(newTab.trim())) {
      setTabs([...tabs, newTab.trim()]);
      setNewTab('');
    }
  };

  const handleRemoveTab = (index: number) => {
    setTabs(tabs.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (tabs.length === 0) {
      toast.error('Bitte fügen Sie mindestens eine Kategorie hinzu');
      return;
    }

    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, 'users', user.uid), {
        email,
        categories: tabs,
        createdAt: new Date().toISOString(),
      });
      toast.success('Registrierung erfolgreich!');
      navigate('/');
    } catch (error) {
      toast.error('Registrierung fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-blue-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl p-8 w-full max-w-md">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Konto erstellen</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">E-Mail</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Passwort</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Kategorien (Max. 6)
            </label>
            <div className="flex gap-2 mb-2">
              <input
                type="text"
                value={newTab}
                onChange={(e) => setNewTab(e.target.value)}
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Kategorie hinzufügen"
              />
              <button
                type="button"
                onClick={handleAddTab}
                className="p-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors"
              >
                <PlusCircle size={24} />
              </button>
            </div>
            <div className="flex flex-wrap gap-2">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className="bg-purple-100 px-3 py-1 rounded-full flex items-center gap-2"
                >
                  <span className="text-purple-800">{tab}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveTab(index)}
                    className="text-purple-800 hover:text-purple-900"
                  >
                    <X size={16} />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-purple-500 text-white py-2 rounded-lg hover:bg-purple-600 transition-colors"
          >
            Registrieren
          </button>
        </form>
      </div>
    </div>
  );
}