import React from 'react';
import { RotateCcw } from 'lucide-react';

interface ProgressBarProps {
  completed: number;
  total: number;
  onReset: () => void;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ completed, total, onReset }) => {
  const percentage = total === 0 ? 0 : Math.round((completed / total) * 100);
  
  return (
    <div className="bg-white rounded-xl shadow-md p-6 mb-8">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-xl font-semibold text-gray-800">Fortschritt</h2>
        {completed > 0 && (
          <button
            onClick={onReset}
            className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 hover:text-gray-800 transition-colors"
            title="Fortschritt zurücksetzen"
          >
            <RotateCcw size={16} />
            Zurücksetzen
          </button>
        )}
      </div>
      <div className="relative h-4 bg-gray-100 rounded-full overflow-hidden">
        <div
          className="absolute top-0 left-0 h-full bg-gradient-to-r from-purple-500 to-purple-600 rounded-full transition-all duration-1000 ease-out"
          style={{
            width: `${percentage}%`,
            transform: 'translateZ(0)',
            willChange: 'width',
          }}
        >
          <div className="absolute inset-0 bg-white/20 animate-shimmer" 
            style={{
              backgroundImage: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent)',
              backgroundSize: '200% 100%',
              animation: 'shimmer 2s infinite'
            }}
          />
        </div>
      </div>
      <div className="mt-2 flex justify-between text-sm text-gray-600">
        <span>{completed} erledigt</span>
        <span>{percentage}%</span>
      </div>
    </div>
  );
};