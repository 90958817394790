import React from 'react';
import { CheckCircle2, Clock, Edit2, Trash2 } from 'lucide-react';
import { Draggable } from 'react-beautiful-dnd';
import { Todo } from './types';

interface TodoItemProps {
  todo: Todo;
  index: number;
  onEdit: (todo: { id: string; text: string }) => void;
  onComplete: (todo: Todo) => void;
  onProgress: (todo: Todo) => void;
  onDelete: (id: string) => void;
  editingTodo: { id: string; text: string } | null;
  updateTodoText: (id: string, text: string) => void;
}

export const TodoItem: React.FC<TodoItemProps> = ({
  todo,
  index,
  onEdit,
  onComplete,
  onProgress,
  onDelete,
  editingTodo,
  updateTodoText,
}) => {
  return (
    <Draggable draggableId={todo.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`flex flex-col sm:flex-row gap-2 p-3 rounded-lg group transition-colors ${
            todo.inProgress ? 'bg-yellow-50' : 'bg-gray-50'
          }`}
        >
          {editingTodo?.id === todo.id ? (
            <textarea
              value={editingTodo.text}
              onChange={(e) => onEdit({ ...editingTodo, text: e.target.value })}
              onBlur={() => updateTodoText(todo.id, editingTodo.text)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  updateTodoText(todo.id, editingTodo.text);
                }
              }}
              className="flex-1 px-2 py-1 border rounded resize-none min-h-[60px]"
              autoFocus
            />
          ) : (
            <div className="flex items-start gap-2 flex-1 min-w-0">
              <p className={`text-gray-800 whitespace-pre-wrap break-words ${todo.completed ? 'line-through text-gray-400' : ''}`}>
                {todo.text}
              </p>
            </div>
          )}
          <div className="flex items-center gap-2 sm:self-start ml-auto">
            <button
              onClick={() => onComplete(todo)}
              className="text-green-500 hover:text-green-600"
              title="Mark as completed"
            >
              <CheckCircle2 size={20} />
            </button>
            <button
              onClick={() => onProgress(todo)}
              className={`${
                todo.inProgress
                  ? 'text-yellow-500 hover:text-yellow-600'
                  : 'text-gray-400 hover:text-yellow-500'
              }`}
              title="Toggle in progress"
            >
              <Clock size={20} />
            </button>
            <button
              onClick={() => onEdit({ id: todo.id, text: todo.text })}
              className="text-gray-500 hover:text-gray-600"
              title="Edit todo"
            >
              <Edit2 size={20} />
            </button>
            <button
              onClick={() => onDelete(todo.id)}
              className="text-red-500 hover:text-red-600"
              title="Delete todo"
            >
              <Trash2 size={18} />
            </button>
          </div>
        </div>
      )}
    </Draggable>
  );
};