import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBYQx8OrfAJbW_r5KtXo8HWeDAqXT44nHI",
  authDomain: "neuecirclelist.firebaseapp.com",
  projectId: "neuecirclelist",
  storageBucket: "neuecirclelist.firebasestorage.app",
  messagingSenderId: "448817848499",
  appId: "1:448817848499:web:234600cd54dadeae03d0c6"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);