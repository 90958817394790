import React from 'react';

interface MotivationalModalProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

export const MotivationalModal: React.FC<MotivationalModalProps> = ({ message, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-purple-600 rounded-2xl shadow-2xl p-8 max-w-2xl w-full mx-4 transform animate-scale-up">
        <div className="text-center">
          <p className="text-4xl md:text-5xl font-bold text-white mb-8 leading-tight">
            {message}
          </p>
          <button
            onClick={onClose}
            className="bg-white text-purple-600 px-8 py-3 rounded-xl text-xl font-semibold hover:bg-purple-100 transition-colors transform hover:scale-105"
          >
            Danke Manu du auch!
          </button>
        </div>
      </div>
    </div>
  );
};