import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { TodoItem } from './TodoItem';
import { Todo } from './types';

interface TodoCategoryProps {
  category: string;
  todos: Todo[];
  onEdit: (todo: { id: string; text: string }) => void;
  onComplete: (todo: Todo) => void;
  onProgress: (todo: Todo) => void;
  onDelete: (id: string) => void;
  editingTodo: { id: string; text: string } | null;
  updateTodoText: (id: string, text: string) => void;
}

export function TodoCategory({
  category,
  todos,
  onEdit,
  onComplete,
  onProgress,
  onDelete,
  editingTodo,
  updateTodoText,
}: TodoCategoryProps) {
  const filteredTodos = todos.filter((todo) => todo.category === category);

  return (
    <Droppable droppableId={category}>
      {(provided) => (
        <div 
          ref={provided.innerRef} 
          {...provided.droppableProps}
          className="bg-white rounded-xl shadow-md p-6"
        >
          <h2 className="text-xl font-semibold text-gray-800 mb-4 pb-2 border-b">
            {category}
          </h2>
          <div className="space-y-2">
            {filteredTodos.map((todo, index) => (
              <TodoItem
                key={todo.id}
                todo={todo}
                index={index}
                onEdit={onEdit}
                onComplete={onComplete}
                onProgress={onProgress}
                onDelete={onDelete}
                editingTodo={editingTodo}
                updateTodoText={updateTodoText}
              />
            ))}
            {filteredTodos.length === 0 && (
              <p className="text-gray-500 text-center py-4">Keine Aufgaben vorhanden</p>
            )}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}