import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { PlusCircle, X, ArrowLeft, Edit2, Check } from 'lucide-react';
import toast from 'react-hot-toast';

export default function Settings() {
  const { user } = useAuth();
  const [categories, setCategories] = useState<string[]>([]);
  const [newCategory, setNewCategory] = useState('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingText, setEditingText] = useState('');

  useEffect(() => {
    if (!user) return;

    const fetchCategories = async () => {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setCategories(userDoc.data().categories || []);
      }
    };

    fetchCategories();
  }, [user]);

  const handleAddCategory = async () => {
    if (!newCategory.trim()) return;
    if (categories.length >= 6) {
      toast.error('Maximal 6 Kategorien erlaubt');
      return;
    }
    if (categories.includes(newCategory.trim())) {
      toast.error('Diese Kategorie existiert bereits');
      return;
    }

    try {
      const updatedCategories = [...categories, newCategory.trim()];
      await updateDoc(doc(db, 'users', user!.uid), {
        categories: updatedCategories,
      });
      setCategories(updatedCategories);
      setNewCategory('');
      toast.success('Kategorie hinzugefügt');
    } catch (error) {
      toast.error('Fehler beim Hinzufügen der Kategorie');
    }
  };

  const handleRemoveCategory = async (index: number) => {
    try {
      const updatedCategories = categories.filter((_, i) => i !== index);
      await updateDoc(doc(db, 'users', user!.uid), {
        categories: updatedCategories,
      });
      setCategories(updatedCategories);
      toast.success('Kategorie entfernt');
    } catch (error) {
      toast.error('Fehler beim Entfernen der Kategorie');
    }
  };

  const startEditing = (index: number, category: string) => {
    setEditingIndex(index);
    setEditingText(category);
  };

  const handleUpdateCategory = async (index: number) => {
    if (!editingText.trim() || categories.includes(editingText.trim())) {
      toast.error('Ungültiger Name oder Kategorie existiert bereits');
      return;
    }

    try {
      const updatedCategories = [...categories];
      updatedCategories[index] = editingText.trim();
      await updateDoc(doc(db, 'users', user!.uid), {
        categories: updatedCategories,
      });
      setCategories(updatedCategories);
      setEditingIndex(null);
      toast.success('Kategorie aktualisiert');
    } catch (error) {
      toast.error('Fehler beim Aktualisieren der Kategorie');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-blue-50 p-4">
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center mb-8">
          <Link
            to="/"
            className="flex items-center text-gray-600 hover:text-gray-800 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Zurück zu Aufgaben
          </Link>
        </div>

        <div className="bg-white rounded-xl shadow-md p-6 mb-6">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">Kategorien verwalten</h1>

          <div className="flex gap-2 mb-6">
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Neue Kategorie"
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            />
            <button
              onClick={handleAddCategory}
              className="p-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors"
            >
              <PlusCircle size={24} />
            </button>
          </div>

          <div className="space-y-3">
            {categories.map((category, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 bg-gray-50 rounded-lg group"
              >
                {editingIndex === index ? (
                  <div className="flex-1 flex items-center gap-2">
                    <input
                      type="text"
                      value={editingText}
                      onChange={(e) => setEditingText(e.target.value)}
                      className="flex-1 px-3 py-1 border rounded"
                      autoFocus
                    />
                    <button
                      onClick={() => handleUpdateCategory(index)}
                      className="text-green-500 hover:text-green-600"
                    >
                      <Check size={20} />
                    </button>
                  </div>
                ) : (
                  <span className="text-gray-800">{category}</span>
                )}
                <div className="flex items-center gap-2">
                  {editingIndex !== index && (
                    <button
                      onClick={() => startEditing(index, category)}
                      className="text-gray-500 hover:text-gray-600"
                    >
                      <Edit2 size={20} />
                    </button>
                  )}
                  <button
                    onClick={() => handleRemoveCategory(index)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <X size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}